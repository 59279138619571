<template>
  <div
    class="hidden lg:flex w-full md:w-2/5 lg:w-1/2 justify-center items-center bg-theme-darkest p-16 fixed right-0 top-0 bottom-0"
  >
    <img
      alt="Login"
      class="w-full h-full absolute"
      src="@/assets/images/auth-pattern.png"
    />
    <div
      class="w-full max-w-sm bg-white rounded-xl shadow-darkbg px-10 py-12 relative space-y-8"
    >
      <div class="flex items-center">
        <div class="flex-shrink-0 w-12 h-12 mr-4">
          <Icon class="w-full h-full" name="high-quality" />
        </div>
        <div>
          <p class="text-black font-base font-semibold">The Best Products</p>
          <span class="text-theme-light"
            >{{ commaFormat(totalCount) }} of them and growing.</span
          >
        </div>
      </div>
      <div class="flex items-center">
        <div class="flex-shrink-0 w-12 h-12 mr-4">
          <Icon class="w-full h-full" name="easy-payments" />
        </div>
        <div>
          <p class="text-black font-base font-semibold">
            Multiple Payment Options
          </p>
          <span class="text-theme-light">Pay now or pay later.</span>
        </div>
      </div>
      <div class="flex items-center">
        <div class="flex-shrink-0 w-12 h-12 mr-4">
          <Icon class="w-full h-full" name="top-brands" />
        </div>
        <div>
          <p class="text-black font-base font-semibold">Super fast Shipping</p>
          <span class="text-theme-light">Most orders ship same day.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mymixin from "@/modules/mixins";
import { ref, inject } from "vue";

export default {
  name: "InformationColumn",
  mixins: [Mymixin],
  setup() {
    const axios = inject("axios");
    var totalCount = ref("0");
    function variantCounts() {
      axios.api
        .get("/variants-count")
        .then((res) => (totalCount.value = res.data));
    }
    variantCounts();

    return {
      totalCount,
    };
  },
};
</script>
